import React from "react";
import Helmet from "react-helmet";
import { Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import "../assets/css/App.css";
import "../assets/css/index.css";
import "../assets/css/Section.css";
import bbiscareers from "../assets/Images/Careers/bbis-careers.jpeg";
import bbiscareers2 from "../assets/Images/Careers/bbis-careers-2.jpeg";
import bbiscareers3 from "../assets/Images/Careers/bbis-careers-3.jpeg";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default () => (
  <Layout>
    <SEO
      title="Careers at BBIS Commercial Heating"
      description="Do you want to work as a commercial boiler engineer in London? BBIS Heating have some opportunities to work in the commercial heating &amp; domestic heating maintenance"
      keywords="BBIS careers, heating engineering jobs, gas apprentices, heating apprentice, potters bar heating, potters bar gas engineering jobs, london heating eningeering jobs"
    />
    <div class="white-segment">
      <h1 class="heading-color text-left pb-2">Why choose a career with us?</h1>
      <p>
        BBIS commercial heating are a rapidly expanding company, we are always
        looking for commercial and domestic gas engineers to join our friendly
        team. Applicants must have at least 1 years’ experience in the industry,
        hold all relevant certificates and have a full UK driving licence.
      </p>
      <p class="pb-4">
        We offer an extremely competitive salary based on experience plus a
        Christmas bonus. You will be provided with all tools, van and mobile
        phone. We also arrange all-inclusive team activities such as gokarting
        or golf.
      </p>
      <Row>
        <Col sm>
          <img
            alt=""
            class="rounded-circle pb-4 mx-auto d-block"
            src={bbiscareers}
          />
          <h3 class="heading-color text-left">Competitive salary</h3>
          <p class="text-left">
            We offer an extremely competitive salary based on experience plus a
            Christmas bonus.
          </p>
        </Col>
        <Col sm>
          <img
            alt=""
            class="rounded-circle pb-4 mx-auto d-block"
            src={bbiscareers2}
          />
          <h3 class="heading-color text-left">Work vehicle provided</h3>
          <p class="text-left">
            You will be provided with all tools, van and mobile phone.
          </p>
        </Col>
        <Col sm>
          <img
            alt=""
            class="rounded-circle pb-4 mx-auto d-block"
            src={bbiscareers3}
          />
          <h3 class="heading-color text-left">Great environment</h3>
          <p class="text-left">
            We pride ourselves on working as a team, with our aim to help you
            develop your skills.
          </p>
        </Col>
      </Row>
      <Button className="p-2" variant="primary" href="#careersanchor">
        See current openings
      </Button>
    </div>
    <div class="grey-segment text-left">
      <h2 class="p-3 heading-color">Gas engineering apprenticeship</h2>
      <p class="p-3">
        We sometimes have vacancies to recruit a trainee engineering apprentice
        to join our team, this would be in conjunction with completing a college
        course. Please get in touch with us for more information.
      </p>
    </div>
    <div class="white-segment text-left" id="careersanchor">
      <h2 class="p-3 heading-color">Current openings</h2>
      <p class="p-3">
        We are currently looking for a domestic and commercial gas engineer to
        join our team. Areas of work will include; Breakdown calls, servicing
        and new installations.
      </p>
      <p class="p-3">All applications need to have:</p>
      <ul>
        <li>
          All relevant gas certificates for domestic and commercial gas work
        </li>
        <li>A good engineering background</li>
        <li>Good diagnostic skills</li>
        <li>Impeccable time keeping</li>
        <li>Be hardworking and reliable</li>
        <li>Take pride in their work</li>
        <li>A clean driving licence</li>
      </ul>
      <p class="p-3">
        You will be paid an industry leading salary based on experience and
        technical knowledge. A pension, van and phone are all provided. To apply
        for the position please use the button below and email us using our
        contact form.
      </p>
      <StyledButton href="/contact" variant="primary" size="lg">
        Contact us
      </StyledButton>
    </div>
  </Layout>
);
